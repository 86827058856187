import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['prefectureSelect', 'citySelect']

  connect() {
    const prefectureId = this.prefectureSelectTarget.value
    const selectedCityId = this.citySelectTarget.value

    // 初期化時に市町村をロード
    if (prefectureId) {
      this.loadCities(prefectureId, selectedCityId)
    }
  }

  filterCities() {
    const prefectureId = this.prefectureSelectTarget.value

    // 都道府県が選択されていない場合は処理をスキップ
    if (!prefectureId) {
      this.citySelectTarget.innerHTML =
        "<option value=''>選択してください</option>"
      return
    }

    // 市町村のロード
    this.loadCities(prefectureId)
  }

  loadCities(prefectureId, selectedCityId = null) {
    const url = `/api/prefectures/${prefectureId}/cities`
    const locale = this.prefectureSelectTarget.dataset.locale

    fetch(url)
      .then(response => response.json())
      .then(data => {
        let options = data.map(
          city =>
            `<option value='${city.id}' ${
              city.id == selectedCityId ? 'selected' : ''
            }>${locale === 'en' ? city.name : city.name_ja}</option>`
        )
        options.unshift(
          locale === 'en'
            ? `<option value=''>Select...</option>`
            : `<option value=''>選択してください</option>`
        )
        this.citySelectTarget.innerHTML = options.join('')
      })
  }
}
