import {Controller} from '@hotwired/stimulus'

// Connects to data-controller="online-planning-booking-date-controller"
export default class extends Controller {
  static targets = ['candidateDate', 'startTime', 'endTime']

  connect() {}

  onInput() {
    this.updateRequired();
  }

  // 第2希望以降の 日付・開始時日・終了日時 のどれかのinputに入力があったら、その希望の他のinputにrequiredを付与
  updateRequired() {
    const candidateDate = this.candidateDateTarget;
    const startTime = this.startTimeTarget;
    const endTime = this.endTimeTarget;

    // 第1希望の場合、フォーム遷移時に付与しているrequiredを維持
    if ( candidateDate.id === 'first-candidate-date' || startTime.id === 'first-start-time' || endTime.id === 'first-end-time') {
      return; // ここで処理を終了する
    }

    const hasValue = [candidateDate, startTime, endTime].some(input => input.value.trim() !== '');

    [candidateDate, startTime, endTime].forEach(input => {
      input.required = hasValue;
    });
  }

  // 以下になるようにしている
  //
  // 開始時間(18:00)を入力して
  // 終了時間を開始時間より前(17:00)にしようとすると
  // 終了時間は開始時間と同じ(18:00)で設定される
  //
  // 終了時間(20:00)を入力して
  // 開始時間を終了時間より後(21:00)にしようとすると
  // 終了時間は開始時間と同じ(20:00)で設定される

  // 開始時間が変更された時の処理
  onStartTimeInput() {
    const startTimeInput = this.startTimeTarget;
    const endTimeInput = this.endTimeTarget;

    const startTime = this.parseTime(startTimeInput.value);
    const endTime = this.parseTime(endTimeInput.value);

    if (!startTime || !endTime) return;

    // 開始時間が終了時間より後なら、開始時間を終了時間に揃える
    if (startTime > endTime) {
      startTimeInput.value = endTimeInput.value;
    }
  }

  // 終了時間が変更された時の処理
  onEndTimeInput() {
    const startTimeInput = this.startTimeTarget;
    const endTimeInput = this.endTimeTarget;

    const startTime = this.parseTime(startTimeInput.value);
    const endTime = this.parseTime(endTimeInput.value);

    if (!startTime || !endTime) return;

    // 終了時間が開始時間より前なら、終了時間を開始時間に揃える
    if (endTime < startTime) {
      endTimeInput.value = startTimeInput.value;
    }
  }

  // Helper function to parse time (HH:MM) into a Date object
  parseTime(time) {
    if (!time) return null;

    const [hours, minutes] = time.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) return null;

    const date = new Date();
    date.setHours(hours, minutes, 0, 0);

    return date;
  }
}
