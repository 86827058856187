import {Controller} from '@hotwired/stimulus'

// Connects to data-controller="online-planning-booking-calculate-controller"
export default class extends Controller {
  static values = { hourlyRate: Number }
  static targets = ['duration', 'amount']

  connect() {}

  calculateAmount() {
    const duration = parseFloat(this.durationTarget.value) || 0;
    const amount = Math.floor(this.hourlyRateValue * duration);
    this.amountTarget.textContent = this.formatCurrency(amount);
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }).format(amount);
  }

  onDurationChange() {
    this.calculateAmount()
  }
}
