import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/airbnb.css'

// Connects to data-controller="flatpickr"
export default class extends Controller {
  connect() {
    const kind = this.element.dataset.kind

    if (kind === 'home-visit-booking') {
      flatpickr(this.element, {
        dateFormat: 'm/d/Y',
        minDate: 'today',
        allowInput: true // required を有効にするため
      })
    } else if (kind === 'online-planning') {
      flatpickr(this.element, {
        dateFormat: 'm/d/Y H:i',
        enableTime: true,
        time_24hr: false,
        minDate: new Date(Date.now()),
        allowInput: true // required を有効にするため
      })
    } else if (kind === 'online-planning-date') {
      flatpickr(this.element, {
        dateFormat: 'm/d/Y',
        minDate: 'today',
        allowInput: true // required を有効にするため
      })
    } else if (kind === 'all-date') {
      flatpickr(this.element, {
        // dateFormat: "m/d/Y", TODO: このフォーマットだと登録できない。
        allowInput: true // required を有効にするため
      })
    } else if (kind === 'date-from-today') {
      flatpickr(this.element, {
        // dateFormat: "m/d/Y", TODO: このフォーマットだと登録できない。
        minDate: 'today',
        allowInput: true // required を有効にするため
      })
    } else if (kind === 'only-time') {
      flatpickr(this.element, {
        enableTime: true,
        noCalendar: true,
      })
    } else {
      flatpickr(this.element, {
        enableTime: true,
        // dateFormat: 'm/d/Y h:i K', TODO: このフォーマットだと登録できない
        time_24hr: false,
        minDate: 'today',
        allowInput: true // required を有効にするため
      })
    }
  }
}
