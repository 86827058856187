import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    console.log('Sync Checkboxes Controller connected!') // デバッグ用
    this.syncCheckboxes()
  }

  syncCheckboxes() {
    // First (allergy) チェックボックスを取得
    const firstCheckboxes = this.element.querySelectorAll(
      "[data-sync-checkboxes-target='first']"
    )
    console.log('First Checkboxes:', firstCheckboxes) // チェックボックスの確認用

    firstCheckboxes.forEach(firstCheckbox => {
      firstCheckbox.addEventListener('change', event => {
        console.log('Event Triggered') // イベント確認

        // チェックボックスに隣接する <span> のテキストを取得
        const text = event.target
          .closest('label')
          ?.querySelector('span')
          ?.innerText.trim()
        console.log('Selected Text:', text) // デバッグ用

        if (!text) return // textがnullの場合は何もしない

        // Second (avoidance) チェックボックスを探し、同じテキストがあればチェックを同期
        const secondCheckboxes = this.element.querySelectorAll(
          "[data-sync-checkboxes-target='second']"
        )
        secondCheckboxes.forEach(secondCheckbox => {
          const secondText = secondCheckbox
            .closest('label')
            ?.querySelector('span')
            ?.innerText.trim()
          if (secondText === text) {
            secondCheckbox.checked = event.target.checked
          }
        })
      })
    })
  }
}
