import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['price', 'stripeFee', 'hostShare', 'guideShare', 'platform']

  connect() {
    this.calculate() // 初期化時に計算を実行
  }

  calculate() {
    // HTMLから設定値を取得
    const stripeRate = parseFloat(this.data.get('stripeRate'))
    const hostRate = parseFloat(this.data.get('hostRate'))
    const guideRate = parseFloat(this.data.get('guideRate'))

    // 入力値
    const price = parseFloat(this.priceTarget.value) || 0 // 販売金額

    // 計算
    const stripeFee = price * stripeRate // Stripe手数料
    const netAmount = price - stripeFee // 手数料を引いた金額

    const hostShare = netAmount * hostRate // Hostの取り分
    const guideShare = netAmount * guideRate // Guideの取り分
    const platformFee = price - (stripeFee + hostShare + guideShare) // 残額

    // 各値を表示
    this.stripeFeeTarget.textContent = stripeFee.toFixed(0)
    this.hostShareTarget.textContent = hostShare.toFixed(0)
    this.guideShareTarget.textContent = guideShare.toFixed(0)
    this.platformTarget.textContent = platformFee.toFixed(0)
  }
}
